import React, { useState, useLayoutEffect } from 'react';
import { Link } from 'gatsby';
import Styleswrapper from '../components/StylesWrapper';
import PageMeta from '../components/blocks/helmet/PageMeta';
import Mainheader from '../components/MainHeader';
import '../styles/choicepad.css';
import { gsap } from 'gsap';
import { motion } from 'framer-motion';

// icons
import SunIcon from '../images/icons/sun.png';
import MoonIcon from '../images/icons/moon.png';
import OwlIcon from '../images/icons/owl.png';
import OmeletIcon from '../images/icons/omelet.png';
import TeacupIcon from '../images/icons/teacup.png';
import NewspaperIcon from '../images/icons/newspaper.png';
import NoodlesIcon from '../images/icons/noodles.png';
import PretzelIcon from '../images/icons/pretzel.png';
import TvIcon from '../images/icons/tv.png';
import TeapotIcon from '../images/icons/teapot.png';
import DonutIcon from '../images/icons/donut.png';
import CatIcon from '../images/icons/cat.png';
import CoffeecupIcon from '../images/icons/coffeecup.png';

// images
import SlangbustersLogo from '../images/index/slangbusters.svg';
import BeringerLogo from '../images/index/beringer.svg';


const Index = () => {

  const [clockGreeting, setClockGreeting] = useState([]);
  const [clockToys, setClockToys] = useState([]);
  const currentHour = new Date().getHours();

  useLayoutEffect(() => {
    if (currentHour >= 5 && currentHour < 12) {
      setClockGreeting(["Good morning", "☀️", SunIcon]);
      setClockToys([{ icon: '🍳', name: OmeletIcon }, { icon: '☕', name: TeacupIcon }, { icon: '📰', name: NewspaperIcon }]);
    }
    else if (currentHour > 11 && currentHour < 17) {
      setClockGreeting(["Good afternoon", "☀️", SunIcon]);
      setClockToys([{ icon: '🍜', name: NoodlesIcon }, { icon: '🥨', name: PretzelIcon }, { icon: '📺', name: TvIcon }]);
    }
    else if (currentHour > 16 && currentHour <= 23) {
      setClockGreeting(["Good evening", "🌙", MoonIcon]);
      setClockToys([{ icon: '🫖', name: TeapotIcon }, { icon: '🥯', name: DonutIcon }, { icon: '🐈‍⬛', name: CatIcon }]);
    }
    else {
      setClockGreeting(["Good health to you", "🌙", MoonIcon]);
      setClockToys([{ icon: '🦉', name: OwlIcon }, { icon: '🍵', name: CoffeecupIcon }, { icon: '🦉', name: OwlIcon }]);
    }
  }, [currentHour]);

  // ICON
  const greetingIconVariant = {
    initial: {
      opacity: 0,
      y: "100%",
      rotate: -15,
    },
    animate: {
      opacity: 1,
      y: 0,
      rotate: 0,
      transition: {
        duration: 1,
        ease: "backOut",
        delay: 0.4,
      }
    },
  };

  // GREETING MESSAGE
  const greetingMessageVariant = {
    initial: {
      opacity: 0,
      y: "100%",
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "circOut",
        delay: 0.3,
      }
    },
  };

  // FIRST BOX
  const refreshmentsVariant = {
    initial: {
      opacity: 0,
      y: "15%",
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.45,
        ease: "circOut",
        delay: 1.2,
      }
    },
  };

  // SECOND BOX
  const workVariant = {
    initial: {
      opacity: 0,
      y: "15%",
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.45,
        ease: "circOut",
        delay: 1.3,
      }
    },
  }

  // nav
  const navigationVariant = {
    initial: {
      y: "100%",
      opacity: 0,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: "circOut",
        delay: 1.3,
      }
    },
  }

  useLayoutEffect(() => {
    let tl = gsap.timeline({
      defaults: {
        ease: "power1.out",
        duration: 5,
      }
    });

    tl.to(".master-bg-block", {
      "--bg-1": "#00070D",
      "--bg-2": "#00070D",
      "--bg-3": "#00070D",
      "--bg-4": "#27084D",
      "--bg1-stop": "0%",
      "--bg2-stop": "0%",
      "--bg3-stop": "65%",
      "--bg4-stop": "100%",
    });

    return () => {
      tl.kill();
    }
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  useLayoutEffect(() => {
    if (window.innerWidth < 1000) {
      setIsMobile(true);
    }
  }, []);

  return (
    <Styleswrapper
      bgColor1="#00070D"
      bgColor2="#00070D"
      bg1Stop="65%"
      bg2Stop="100%"
      noise="true"
    >

      <PageMeta
        theme="#00070D"
        title="Portfolio of Nihar Bhagat — Designer"
        description="This is a snapshot of my Creative Practice"
        image="../images/social/default.jpg"
        twitterImage="../images/social/twitter.jpg"
      />

      {!isMobile && (
        <Mainheader
          classes="cp-header"
          bgColor1="#00070D"
          bgColor2="#00070D"
          bg1Stop="65%"
          bg2Stop="100%"
          noise="true"
          variants={navigationVariant}
        />
      )}

      <div className={isMobile ? `cp-container cp-container-mobile` : 'cp-container'}>
        <section className="cp-main">
          <div className="cp-main-wrapper">

            <div className="cp-greeting-icon">
              <motion.img
                src={clockGreeting[2]}
                alt={clockGreeting[1]}
                loading='eager'
                draggable='false'
                variants={greetingIconVariant}
                initial='initial'
                animate='animate'
              />
            </div>

            <div className="cp-greeting-msg">
              <motion.p
                variants={greetingMessageVariant}
                initial='initial'
                animate='animate'
              >
                {clockGreeting[0]}
              </motion.p>
            </div>

            <div className="cp-intro">
              <motion.div className="cp-intro-refreshments"
                variants={refreshmentsVariant}
                initial='initial'
                animate='animate'
              >
                <div className='cp-intro-refreshments-icons'>

                  {clockToys.map((toy, index) => (
                    <div
                      key={index}
                      className='cp-intro-refreshments-icon'
                    >
                      <img
                        src={toy.name}
                        alt={toy.icon}
                        loading='eager'
                        draggable='false'
                      />
                    </div>
                  ))}

                </div>
                {!isMobile && (
                  <Link className="cp-intro-link" to="/about">
                    <span>Know more about me</span>
                  </Link>
                )}
              </motion.div>
              <motion.div
                className="cp-intro-main-copy"
                variants={refreshmentsVariant}
                initial='initial'
                animate='animate'
              >
                <h1>
                  <span className="copy-line-1">
                    <span className="copy-line-small">My name is </span> Nihar Bhagat</span>
                  <span className="copy-line-2">
                    <span className="copy-line-small">and this is a snapshot of my </span>Creative Practice</span></h1>
              </motion.div>

              {!isMobile && (
                <motion.div
                  className="cp-intro-work"
                  variants={workVariant}
                  initial='initial'
                  animate='animate'
                >
                  <div className="cp-intro-work-header">
                    <h2 className="cp-intro-work-copy">
                      <span className='copy-line-small'>I convert thoughts into things</span>
                      <span>That's all I do!</span>
                    </h2>
                    <Link className="cp-intro-link" to="/work">
                      <span>View all works</span>
                    </Link>
                  </div>

                  <div className="cp-intro-work-tabs">
                    <Link to="/work/slangbusters" className="cp-intro-work-tab">
                      <div className="work-tab-info">
                        <p className='work-tab-desc'>Designing a culture that makes timeless pieces of work</p>
                        <div className="work-tab-image">
                          <img
                            src={SlangbustersLogo}
                            alt="Slangbusters logo"
                            loading='eager'
                            draggable='false'
                          />
                        </div>
                      </div>
                      <p className="work-tab-type">Case study</p>
                    </Link>

                    <Link to="#" className="cp-intro-work-tab disabled" tabIndex={-1} onClick={(e) => {e.preventDefault()}}>
                      <div className="work-tab-info">
                        <p className='work-tab-desc'>Gifting a much needed logo on his 100th birthday</p>
                        <div className="work-tab-image">
                          <img
                            src={BeringerLogo}
                            alt="Beringer logo"
                            loading='eager'
                            draggable='false'
                          />
                        </div>
                      </div>
                      <p className="work-tab-type">Post-humous charity</p>
                    </Link>
                  </div>
                </motion.div>
              )}

              {isMobile && (
                <motion.div
                  className="cp-intro-work cp-intro-mobile"
                  variants={workVariant}
                  initial='initial'
                  animate='animate'
                >
                  <div className="cp-intro-work-header">
                    <h2 className="cp-intro-work-copy">
                      <div className="wip"></div>
                      <span className='copy-line-small'>Mobile version undergoing maintenance</span>
                    </h2>
                  </div>
                </motion.div>
              )}
            </div>

          </div>
        </section>
      </div>
    </Styleswrapper>

  );
}

export default Index;
